var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1 row-pointer",attrs:{"headers":_vm.headers,"loading":_vm.loading,"items":_vm.lists,"options":_vm.options,"items-per-page":_vm.tableMeta.per_page,"server-items-length":_vm.tableMeta.total,"item-class":_vm.itemBg,"footer-props":_vm.footer},on:{"update:options":function($event){_vm.options=$event},"update:itemsPerPage":function($event){return _vm.$set(_vm.tableMeta, "per_page", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.tableMeta, "per_page", $event)},"click:row":_vm.rowClick,"dblclick:row":_vm.rowDblClick},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.is_active == 1)?[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-check")]),_vm._v(" "+_vm._s(item.name)+" ")]:[_vm._v(" "+_vm._s(item.name)+" ")]]}},{key:"item.start_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.thDate(item.start_at))+" ")]}},{key:"item.end_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.thDate(item.end_at))+" ")]}},{key:"item.is_active",fn:function(ref){
var item = ref.item;
return [(item.is_active===1)?_c('v-chip',{attrs:{"color":"green"}},[_vm._v(" ตั้งจริง ")]):_vm._e(),(item.is_active===2)?_c('v-chip',{attrs:{"color":"red"}},[_vm._v(" ไม่ได้ตั้ง ")]):_vm._e(),(item.is_active===0)?_c('v-chip',{attrs:{"color":"gray"}},[_vm._v(" ยังไม่ระบุ ")]):_vm._e()]}},{key:"item.types",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.types && item.types.length > 0 ? item.types.map(function(elem){ return elem.name }).join(",") : item.type)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(item.can.update),expression:"item.can.update"}],attrs:{"color":item.is_active == 1 ? 'green' : item.is_active == 2 ?'red' : 'grey'},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.$emit('show-status-dialog', item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-traffic-cone ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.is_active == 1 ? 'เปลี่ยนสถานะ' : 'เปลี่ยนสถานะ'))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(item.can.update),expression:"item.can.update"}],attrs:{"color":"blue"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.$emit('show-qrcode', item.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-qrcode ")])]}}],null,true)},[_c('span',[_vm._v("พิมพ์ QRcode")])])]}}],null,true)},[_c('checkpoint-dialog',{ref:"dialog",on:{"saved":_vm.onSave},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }