<template>
  <v-data-table
    :headers="headers"
    :loading="loading"
    :items="lists"
    :options.sync="options"
    :items-per-page.sync="tableMeta.per_page"
    :server-items-length="tableMeta.total"
    :item-class="itemBg"
    :footer-props="footer"
    class="elevation-1 row-pointer"
    @click:row="rowClick"
    @dblclick:row="rowDblClick"
  >
    <!-- <template v-slot:[`item.approval`]="{ item }">
      <v-icon color="green" v-if="item.approval == 1">mdi-check</v-icon>
      <v-icon color="red" v-else-if="item.approval == 2">mdi-cancel</v-icon>
      <v-icon color="blue" v-else-if="item.approval == 0">mdi-timelapse</v-icon>
      <v-icon color="grey" v-else>mdi-circle</v-icon>
    </template> -->
    <!-- <template v-slot:[`item.no`]="{ item, index }">
      {{ index + ( (options?.page -1) * tableMeta?.per_page) + 1 }}
    </template> -->
    <template v-slot:[`item.name`]="{ item }">
      <template v-if="item.is_active == 1">
        <v-icon color="red">mdi-check</v-icon>
        {{ item.name }}
      </template>
      <template v-else>
        {{ item.name }}
      </template>
    </template>
    <template v-slot:[`item.start_at`]="{ item }">
      {{ thDate(item.start_at) }}
    </template>
    <template v-slot:[`item.end_at`]="{ item }">
      {{ thDate(item.end_at) }}
    </template>
    <template v-slot:[`item.is_active`]="{ item }">
      <v-chip v-if="item.is_active===1" color="green">
        ตั้งจริง
      </v-chip>
      <v-chip v-if="item.is_active===2" color="red">
        ไม่ได้ตั้ง
      </v-chip>
      <v-chip v-if="item.is_active===0" color="gray">
        ยังไม่ระบุ
      </v-chip>
    </template>
    <template v-slot:[`item.types`]="{ item }">
      {{  item.types && item.types.length > 0 ? item.types.map(function(elem){
              return elem.name
          }).join(",") 
          :
          item.type
      }}
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-show="item.can.update"
            @click.prevent.stop="$emit('show-status-dialog', item)"
            :color="item.is_active == 1 ? 'green' : item.is_active == 2 ?'red' : 'grey'"
            v-on="on"
            v-bind="attrs"
          >
            mdi-traffic-cone
          </v-icon>
        </template>
        <span>{{ item.is_active == 1 ? 'เปลี่ยนสถานะ' : 'เปลี่ยนสถานะ' }}</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-show="item.can.update"
            @click.prevent.stop="$emit('show-qrcode', item.id)"
            color="blue"
            v-on="on"
            v-bind="attrs"
          >
            mdi-qrcode
          </v-icon>
        </template>
        <span>พิมพ์ QRcode</span>
      </v-tooltip>
    </template>
    <checkpoint-dialog ref="dialog" v-model="showDialog" @saved="onSave"></checkpoint-dialog>
  </v-data-table>
</template>

<script>
import { listToday, toggleActive } from '@/api/checkpoint'
import { mapGetters } from 'vuex'
import checkpointDialog from '../components/checkpointDialog'

export default {
  methods: {
    onSave() {
      
    },
    getList() {
      this.loading = true
      const { page, itemsPerPage } = this.options
      const param = {
        page: page,
        limit: itemsPerPage,
        bureau: this.bureauId ?? undefined,
        division: this.divisionId ?? undefined,
        station: this.stationId ?? undefined,
        showActive: this.showActive,
        showInActive: this.showInActive,
        checkpointType: this.checkpointType,
      }
      this.lists = []
      listToday(param)
        .then(res => {
          this.lists = res.data
          let meta = res.meta
          let itemsPerPage = parseInt(meta.per_page)
          this.tableMeta = {
            itemsPerPage: itemsPerPage,
            per_page: itemsPerPage,
            page: meta.current_page,
            total: meta.total,
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.loading = false
        })
    },
    setActive(id) {
      // console.log(id)
      // const index = this.lists.findIndex(l => l.id == id)
      // toggleActive(id).then(res => {
      //   if (res.success) {
      //     this.$toast.success({
      //       title: 'Info',
      //       message: 'เปลี่ยนสถานะเรียบร้อยแล้ว',
      //     })
      //     this.lists[index].is_active = res.active
      //   }
      // })
      this.showDialog = true
      this.$emit('show-status-dialog', id)
    },
    rowClick(e, data) {
      this.$emit('row-click', data.item)
    },
    rowDblClick(e, data) {
      // console.log(data.item)
      this.$emit('row-dbl-click', data.item)
    },
    // utils
    thDate(d) {
      return this.moment(d)
        .add(543, 'year')
        .format('D MMMYYYY HH:mm')
    },
    itemBg(item) {
      return item?.is_active == 1 ? 'green lighten-4' : item?.is_active == 2 ? 'red lighten-4' : ''
    },
  },
  props: {
    type: {
      type: String,
      default: '',
    },
    showActive: {
      type: Boolean,
      default: false,
    },
    showInActive: {
      type: Boolean,
      default: false,
    },
    checkpointType: {
      type: Number,
      default: undefined,
    },
  },
  watch: {
    options: {
      handler() {
        this.getList()
      },
      deep: true,
    },
    bureauId(v) {
      this.getList()
    },
    divisionId(v) {
      this.getList()
    },
    stationId(v) {
      this.getList()
    },
    showActive() {
      this.getList()
    },
    showInActive() {
      this.getList()
    },
    checkpointType() {
      this.getList()
    },
  },
  created() {
    // this.getList()
  },
  data() {
    return {
      loading: false,
      showDialog: false,
      headers: [
        // {
        //   text: 'สถานะ',
        //   value: 'approval',
        //   sortable: false,
        // },
        // {
        //   text: 'ลำดับ',
        //   sortable: false,
        //   value: 'no',
        // },
        {
          text: 'จุดตรวจ',
          sortable: false,
          value: 'name',
        },
        {
          text: 'ประเภท',
          sortable: false,
          value: 'types',
        },
        {
          text: 'สถานที่',
          sortable: false,
          value: 'address',
        },
        {
          text: 'เริ่ม',
          sortable: false,
          value: 'start_at',
        },
        { text: 'ถึง', value: 'end_at', sortable: false },
        {
          text: 'สถานะ',
          value: 'is_active',
          sortable: false,
        },
        {
          text: 'หัวหน้าจุดตรวจ',
          value: 'chief',
          sortable: false,
        },
        {
          text: 'เบอร์โทรศัพท์',
          value: 'chief_phone',
          sortable: false,
        },
        {
          text: 'สถานี',
          value: 'station',
          sortable: false,
        },
        {
          text: 'บก./ภจว.',
          value: 'division',
          sortable: false,
        },
        {
          text: 'บช.',
          value: 'bureau',
          sortable: false,
        },
        {
          text: 'หมายเหตุ',
          value: 'remark',
          sortable: false,
        },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      footer: {
        itemsPerPageOptions: [10, 50, 100, 200],
        showCurrentPage: true,
        showFirstLastPage: true,
      },
      tableMeta: {
        itemsPerPage: 10,
        per_page: 10,
        page: 1,
        total: 0,
      },
      lists: [],
      options: {},
    }
  },
  components: {
    checkpointDialog
  },
  computed: {
    ...mapGetters('Appfilter', ['bureauId', 'divisionId', 'stationId']),
  },
}
</script>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
