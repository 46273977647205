<template>
  <v-container fluid>
    <v-card elevation="2" :loading="loading">
      <v-card-title primary-title>
        <div>
          <div class="headline">
            <v-icon color="orange">
              mdi-traffic-cone
            </v-icon>
            จุดตรวจวันนี้
          </div>
          <span class="grey--text subtitle-2">ข้อมูลจุดตรวจที่มีในวันนี้</span>
          <v-alert type="info" dense>กรณีจุดตรวจใดมีการปฏิบัติจริง กรุณากดเปลี่ยนสถานะ เป็นตั้งจริงด้วย (ปุ่ม
            <v-icon>mdi-traffic-cone</v-icon>)(ถ้าไม่ตั้งจุดตรวจจะไม่สามารถรายงานผลการปฏิบัติได้)
          </v-alert>
          <v-alert type="info" dense>
            **ในกรณีที่ได้บันทึกแผนการตั้งจุดตรวจไว้แล้ว ถ้าไม่สามารถตั้งจริงได้ กรุณากดเปลี่ยนสถานะเป็น ไม่ได้ตั้งจุดตรวจ
            และกรุณาระบุ ปัญหาหรือรายละเอียดด้วย
          </v-alert>
          <!-- <v-alert type="warning" dense>
            *** จุดตรวจที่ยังไม่ผ่านการอนุมัติจะไม่สามารถตั้งจุดตรวจได้
          </v-alert> -->
        </div>
        <v-spacer></v-spacer>

        <div style="display: flex; width: 100%; justify-content: flex-end">
          <div>
            <v-select :items="checkpointTypes" v-model="checkpointType" item-text="name" item-value="id"
              label="เลือกประเภท" style="margin-right: 5px;" solo-inverted :disabled="loading" hide-details single-line
              clearable class="shrink pr-1 m-1">
            </v-select>
            <v-switch v-model="showActive" :disabled="showInActive || loading" label="แสดงเฉพาะที่ตั้งจริง"
              :hide-details="true" inset></v-switch>
            <v-switch v-model="showInActive" :disabled="showActive || loading" label="แสดงเฉพาะที่ไม่ได้ตั้ง"
              :hide-details="true" inset></v-switch>
            <v-btn color="indigo darken-2" dark class="ml-2" @click="exportClick" :disabled="loading"
              style="margin-top: 20px;">
              {{ this.loading ? 'Exporting please wait...' : 'Export Excel' }}
            </v-btn>
          </div>
        </div>

      </v-card-title>
      <!-- <checkpoint-table 
        ref="cpTable" 
        @row-click="rowClick" 
        @row-dbl-click="rowDblClick" 
        :show-active="showActive"
        @show-qrcode="showQrcode">
      </checkpoint-table> -->
      <checkpoint-table ref="cpTable" :show-active="showActive" :show-in-active="showInActive"
        :checkpoint-type="checkpointType" @row-click="rowClick" @row-dbl-click="rowDblClick" @show-qrcode="showQrcode"
        @show-status-dialog="showStatusDialog"></checkpoint-table>
    </v-card>

    <!-- pdf dialog -->
    <pdf-dialog ref="pdf"></pdf-dialog>

    <!-- detail dialog -->
    <checkpoint-dialog ref="cdialog" v-model="dialog" :visible="dialog" />

    <checkpoint-status-dialog ref="csDialog" v-model="showCsDialog" @saved="onSave"></checkpoint-status-dialog>

  </v-container>
</template>

<script>
import checkpointTable from './partials/checkpointTable'
import pdfDialog from '@/components/pdfDialog'
import checkpointDialog from '@/components/checkpointDetailDialog'
import checkpointStatusDialog from './components/checkpointDialog'
import { qrcode, exportTodayExcel } from '@/api/checkpoint'
import { mapGetters } from 'vuex'
export default {
  methods: {
    onSave() {
      this.showCsDialog = false
      this.$refs.cpTable.getList()
    },
    exportClick() {
      // const url = 'https://tpcc.police.go.th/2021/api/v2/checkpointTodayExport';
      // window.location.href = url;
      if (!this.checkpointType || !this.dateStart || !this.dateEnd) {
        alert('เนื่องจากข้อมูลมีจำนวนมาก กรุณาเลือกตัวกรอง ประเภทด่านตรวจหรือห้วงวันที่ก่อนทำการ export ข้อมูล')
        return;
      }

      const params = {
        bureau: this.bureauId,
        division: this.divisionId,
        station: this.stationId,
        showActive: this.showActive,
        showInActive: this.showInActive,
        checkpointType: this.checkpointType,
      }
      this.loading = true
      exportTodayExcel(params)
        .then(response => {
          this.loading = false
          // const url = URL.createObjectURL(new Blob([response]));
          // const link = document.createElement('a');
          // link.href = url;
          // link.setAttribute('download', `download.xlsx`);
          // document.body.appendChild(link);
          // link.click();

        })
    },
    rowClick(item) {
      this.$refs.cdialog.open(item.id)
    },
    rowDblClick() { },
    async showQrcode(id) {
      this.loading = true
      const pdfBase64 = await qrcode(id)
      this.$refs.pdf.open(pdfBase64.pdf)
      this.loading = false
    },
    showStatusDialog(item) {
      // console.log(item)
      this.$refs.csDialog.init(item)
      this.showCsDialog = true
    }
  },
  components: {
    checkpointTable,
    pdfDialog,
    checkpointDialog,
    checkpointStatusDialog
  },
  data() {
    return {
      loading: false,
      dialog: false,
      showActive: false,
      showInActive: false,
      showCsDialog: false,
      checkpointType: undefined,
      checkpointTypes: [
        { id: 1, name: 'จุดกวดขันวินัยจราจร' },
        { id: 2, name: 'จุดตรวจวัดแอลกอฮอล์' },
        { id: 3, name: 'เครื่องวัดความเร็วแบบเคลื่อนที่' },
        { id: 4, name: 'จุดตรวจตาม พรก.ฉุกเฉิน' },
        { id: 5, name: 'จุดตรวจสอบน้ำหนักรถบรรทุก' },
        { id: 6, name: 'ป้องกันปราบปรามอาชญากรรม' },
        { id: 7, name: 'ตรวจยาเสพติด' },
        { id: 8, name: 'ป้องกันการแพร่ระบาดโควิด' },
        { id: 9, name: 'ควันดำ/เสียงดัง' },
        { id: 10, name: 'ความมั่นคงตามแนวชายแดน' },
        { id: 11, name: 'อื่นๆ' },
      ]
    }
  },
  computed: {
    ...mapGetters('Appfilter', ['bureauId', 'divisionId', 'stationId', 'dateStart', 'dateEnd']),
  },
  async created() { },
}
</script>

<style></style>
